do ->
    StartupInvestor = (fcModel, transformFormRequestToFormData) ->
        StartupInvestor = fcModel '/api/startupinvestors/:id/', {
            id: '@id'
        }, {
            create:
                method: 'POST'
                transformRequest: transformFormRequestToFormData
                headers:
                    'Content-type': undefined

            update:
                method: 'PUT'
                transformRequest: transformFormRequestToFormData
                headers:
                    'Content-type': undefined
        }

        StartupInvestor


    PanelFeedback = (fcModel) ->
        PanelFeedback = fcModel '/api/panelfeedbacks/:id/',
            id: '@id'

        PanelFeedback


    FundDistributionRecipient = (fcModel, $http) ->
        FundDistributionRecipient = fcModel '/api/funddistributionrecipients/:id/',
            id: '@id'

        FundDistributionRecipient.prototype.resendVerificationCode = ->
            $http.post @urls.resend_verification_code

        FundDistributionRecipient


    FundUpdateItem = (fcModel) ->
        FundUpdateItem = fcModel '/api/fundupdateitems/:id/',
            id: '@id'

        FundUpdateItem


    FundRunway = (fcModel) ->
        FundRunway = fcModel '/api/fundrunways/:id/',
            id: '@id'

        FundRunway.prototype.calc_months_of_runway = ->
            months = null
            if @current_cash_balance > 0 and @monthly_burn_rate > 0
                months = Math.floor(@current_cash_balance / @monthly_burn_rate)

            return months


        FundRunway


    FundCodeOfConduct = (fcModel) ->
        FundCodeOfConduct = fcModel '/api/fundcodeofconduct/:id/',
            id: '@id'

        FundCodeOfConduct


    FundClosing = (fcModel, $http) ->
        FundClosing = fcModel '/api/fundclosings/:id/',
            id: '@id'

        FundClosing.prototype.generate_mgmt_fee_payments = ->
            $http
                .post @urls.generate_mgmt_fee_payments
                .then (response) =>
                    angular.extend(@, response.data)

        FundClosing


    FundKPI = (fcModel) ->
        FundKPI = fcModel '/api/fundkpis/:id/',
            id: '@id'

        FundKPI


    FundScreenshot = (fcModel, transformFormRequestToFormData) ->
        FundScreenshot = fcModel '/api/fundscreenshots/:id/', {
            id: '@id'
        }, {
            create:
                method: 'POST'
                transformRequest: transformFormRequestToFormData
                headers:
                    'Content-type': undefined

            update:
                method: 'PUT'
                transformRequest: transformFormRequestToFormData
                headers:
                    'Content-type': undefined
        }

        FundScreenshot


    FundProfile = (fcModel, transformFormRequestToFormData) ->
        FundProfile = fcModel '/api/fundprofiles/:id/', {
            id: '@id'
        }, {
            partialupdate:
                method: 'PATCH'
                transformRequest: (data) -> transformFormRequestToFormData(data, {
                    'json_fields': ['investment_highlights_company_ids']
                })
                headers:
                    'Content-type': undefined
        }

        FundProfile


    FundQAItem = (fcModel) ->
        FundQAItem = fcModel '/api/fundqa/:id/', {
            id: '@id'
        }

        FundQAItem


    FundQuestion = (fcModel) ->
        FundQuestion = fcModel '/api/fundquestions/:id/',
            id: '@id'

        FundQuestion


    FundSensitiveWhitelist = (fcModel) ->
        FundSensitiveWhitelist = fcModel '/api/fundsensitivewhitelists/:id/',
            id: '@id'

        FundSensitiveWhitelist


    Fund = ($http, fcModel, transformFormRequestToFormData, FundProfile) ->
        baseUrl = '/api/funds/:id/'
        Fund = fcModel baseUrl, {
            id: '@id'
        }, {
            partialupdate:
                method: 'PATCH'
                transformRequest: (data) -> transformFormRequestToFormData(data, {
                    'json_fields': [
                        'followon_funds_ownership_percentages',
                        'mgmt_fees',
                        'profile',
                        'set_manual_lifecycle_buckets',
                    ]
                })
                headers:
                    'Content-type': undefined
        }

        Fund.prototype.get_capital_cumulative = (period) ->
            $http.get("/api/fundgraph/#{@id}/capital_cumulative/", {
                params: {
                    'period': period
                }
            })

        Fund.prototype.get_capital_incremental = (period) ->
            $http.get("/api/fundgraph/#{@id}/capital_incremental/", {
                params: {
                    'period': period
                }
            })

        Fund.prototype.get_profile_views_cumulative = (period) ->
            $http.get("/api/fundgraph/#{@id}/profile_views_cumulative/", {
                params: {
                    'period': period
                }
            })

        Fund.prototype.get_profile_views_incremental = (period) ->
            $http.get("/api/fundgraph/#{@id}/profile_views_incremental/", {
                params: {
                    'period': period
                }
            })

        Fund.prototype.get_profile = (success, error) ->
            FundProfile.get({id: @profile.id}, success, error)

        Fund.prototype.$get_investment_target = ->
            $http
                .get(@urls.get_investment_target, {
                    params: {
                        'fund_maximum': @fund_maximum
                        'mgmt_fees': @mgmt_fees
                        'mgmt_fees_years': @mgmt_fees_years
                    }
                })

        Fund


    FundTestimonial = (fcModel) ->
        FundTestimonial = fcModel '/api/fundtestimonials/:id/',
            id: '@id'

        FundTestimonial


    ReferralApplication = (fcModel) ->
        ReferralApplication = fcModel '/api/referralapplications/:id/', {
            id: '@id'
        }, {
            get:
                method: 'GET'
                transformResponse: (data) -> {'referrals': JSON.parse(data)}
        }

        ReferralApplication


    WireRequest = (fcModel, transformFormRequestToFormData, InvestmentTerms, $http) ->
        WireRequest = fcModel '/api/wirerequests/:id/', {
            id: '@id'
        }, {
            create:
                method: 'POST'
                transformRequest: transformFormRequestToFormData
                headers:
                    'Content-type': undefined

            update:
                method: 'PUT'
                transformRequest: transformFormRequestToFormData
                headers:
                    'Content-type': undefined
        }

        WireRequest.prototype.submit = (data)->
            $http
                .patch("/api/wirerequests/#{@id}/submit/", data)
                .then (response) =>
                    angular.extend(@, response.data)

        # Arguments[0] should always be a wire request instance
        fcWireRequest = (instance) ->
            instance.investment_terms = new InvestmentTerms(instance.investment_terms)
            new WireRequest(instance)
        fcWireRequest


    WithheldInvestableCapital = (fcModel) ->
        WithheldInvestableCapital = fcModel '/api/withheldinvestablecapital/:id/', {
            id: '@id'
        }

        WithheldInvestableCapital

    MgmtFeePayment = (fcModel, $http) ->
        MgmtFeePayment = fcModel '/api/mgmtfeepayments/:id/',
            id: '@id'

        MgmtFeePayment.prototype.pay_online = ->
            $http
                .post @urls.pay_online
                .then (response) =>
                    angular.extend(@, response.data)

         MgmtFeePayment.prototype.pay_offline = ->
            $http
                .post @urls.pay_offline
                .then (response) =>
                    angular.extend(@, response.data)

        MgmtFeePayment


    SpecialDistSell = (fcModel, $http) ->
        SpecialDistSell = fcModel '/api/specialdistsell/:id/', {
            id: '@id'
        }

        SpecialDistSell.prototype.generateLegalDoc = ->
            $http.post(@urls.generate_legal_doc, {
                participation: @participation,
                sell_percents_requested: @sell_percents_requested,
            })

        SpecialDistSell.prototype.claim = ->
            $http.post(@urls.claim, {
                bank_account: @selected_bank_account,
                claim_method: @claim_method,
                wire_account: if @selected_wire_account then @selected_wire_account.id else null,
            })

        SpecialDistSell.prototype.abandonLegalDoc = ->
            $http.post(@urls.abandon_legal_doc, {})

        SpecialDistSell


    ExtDistDetails = (fcModel, $http) ->
        ExtDistDetails = fcModel '/api/extdistdetails/:id/', {
            id: '@id'
        }

        ExtDistDetails.prototype.resetTaxForm = ->
            $http.post(@urls.reset_tax_form)

        ExtDistDetails.prototype.submitInvestorDetails = ->
            $http.post(@urls.submit_investor_details, {
                investor_type: @investor_type,
                investor_country: @investor_country,
            })

        ExtDistDetails.prototype.submitMailAddr = ->
            $http.post(@urls.submit_mail_addr, {
                full_name: @full_name,
                street_address_1: @street_address_1,
                street_address_2: @street_address_2,
                city: @city,
                state: @state,
                zip_code: @zip_code,
                country: @country,
            })

        ExtDistDetails.prototype.associateWireAccount = (wireaccount_id) ->
            $http.post(@urls.associate_wire_account, {
                wireaccount_id: wireaccount_id
            })


        ExtDistDetails


    InvestmentExtraLegalDoc = (fcModel, $http) ->
        InvestmentExtraLegalDoc = fcModel '/api/investmentextralegaldocs/:id/', {
            id: '@id'
        }

        InvestmentExtraLegalDoc


    FundElectionSelection = (fcModel, $http) ->
        FundElectionSelection = fcModel '/api/fundelectionselection/:id/', {
            id: '@id'
        }

        FundElectionSelection.prototype.generateLegalDoc = ->
            $http.post(@urls.generate_legal_doc, {
                percentage_to_sell: @percentage_to_sell,
                participation_type: @participation_type,
                doc_type: @doc_type,

            })

        FundElectionSelection.prototype.abandonLegalDoc = ->
            $http.post(@urls.abandon_legal_doc, {
                doc_type: @doc_type,
                participation_type: @participation_type,
            })
        FundElectionSelection


    angular
        .module 'fundersclub.models'
        .factory 'Fund', Fund
        .factory 'FundProfile', FundProfile
        .factory 'StartupInvestor', StartupInvestor
        .factory 'FundRunway', FundRunway
        .factory 'FundCodeOfConduct', FundCodeOfConduct
        .factory 'FundClosing', FundClosing
        .factory 'FundDistributionRecipient', FundDistributionRecipient
        .factory 'FundKPI', FundKPI
        .factory 'FundScreenshot', FundScreenshot
        .factory 'FundUpdateItem', FundUpdateItem
        .factory 'FundQAItem', FundQAItem
        .factory 'FundQuestion', FundQuestion
        .factory 'FundSensitiveWhitelist', FundSensitiveWhitelist
        .factory 'FundTestimonial', FundTestimonial
        .factory 'PanelFeedback', PanelFeedback
        .factory 'ReferralApplication', ReferralApplication
        .factory 'WireRequest', WireRequest
        .factory 'MgmtFeePayment', MgmtFeePayment
        .factory 'WithheldInvestableCapital', WithheldInvestableCapital
        .factory 'SpecialDistSell', SpecialDistSell
        .factory 'InvestmentExtraLegalDoc', InvestmentExtraLegalDoc
        .factory 'ExtDistDetails', ExtDistDetails
        .factory 'FundElectionSelection', FundElectionSelection
