do ->

    EditFundSingleAssetDetailsController = (Fund, FCFundData) ->
        vm = @
        vm.fund = new Fund(FCFundData.fund)

        vm.save = ->
            vm.errors = {}
            fields = [
                'hide_fund_details',
                'profile.fund_display_name',
                'profile.blurb',
                'profile.one_liner',
            ]
            if vm.fund.display_prior_investor
                fields.push('display_prior_investor')

            vm.fund.$save(fields)
                .catch (response) ->
                    angular.extend(vm.errors, response.data)
                .finally () ->
                    vm.fcSave.saveAttemptFinished(vm.errors)

        # Prior investors handling
        loading_investors = [{name: 'Loading...', cssClass: 'loading-placeholder'}]
        vm.prior_investors = loading_investors

        vm.display_prior_investors_dropdown_toggled = (open) ->
            vm.prior_investors = loading_investors
            if not open then return

            Fund.get({id: vm.fund.id}, (response) ->
                vm.prior_investors = response.prior_company_investors
                vm.fund.prior_company_investors = vm.prior_investors
            )

        vm.select_display_prior_investor = (investor) ->
            vm.fund.display_prior_investor = investor.url
            vm.form.$setDirty()

        # End EditFundSingleAssetDetailsController
        return


    EditFundMultiAssetDetailsController = ($scope, $uibModal, Fund, FCFundData) ->
        vm = @
        vm.fund = new Fund(FCFundData.fund)
        vm.fund.multi_asset_fund_logo_display = FCFundData.urls.MEDIA_URL + vm.fund.multi_asset_fund_logo
        default_logo = FCFundData.urls.LOGO_DEFAULT_IMAGE

        vm.onImageCropped = (croppedImage, croppedImageDisplay) ->
            vm.fund.multi_asset_fund_logo = croppedImage
            vm.fund.multi_asset_fund_logo_display = croppedImageDisplay
            vm.form.$setDirty()

        vm.cropImage = ($files) ->
            $uibModal.open({
                animation: false,
                backdrop: false,
                component: 'FcImageCropperModal',
                resolve: {
                    files: -> $files,
                    onCropped: -> vm.onImageCropped,
                },
                windowClass: 'FullTakeoverModal',
            })

        vm.removeLogo = ->
            vm.fund.multi_asset_fund_logo_display = default_logo
            vm.fund.multi_asset_fund_logo = ''

        vm.save = ->
            vm.errors = {}
            vm.fund.$save([
                'profile.fund_display_name',
                'profile.blurb',
                'profile.one_liner',
                'multi_asset_fund_logo',
                'multi_asset_public_blurb',
            ])
                .catch (response) ->
                    angular.extend(vm.errors, response.data)
                .finally () ->
                    vm.fcSave.saveAttemptFinished(vm.errors)

        # End EditFundMultiAssetDetailsController
        return


    # List of updates for a given company.
    EditFundUpdatesController = (FundUpdateItem, FCFundData) ->
        vm = @
        vm.update_items = (new FundUpdateItem(update) for update in FCFundData.fund_updates)
        vm.expanded_child_id = null

        vm.onAdd = (updateItem) ->
            vm.update_items.unshift(updateItem)
            vm.expanded_child_id = updateItem.id

        # Remove specified child from list.
        vm.removeChild = (child_id) ->
            vm.expanded_child_id = null
            child_index = _.findIndex(vm.update_items, (child) -> child.id == child_id)
            vm.update_items.splice(child_index, 1)

        # End FundUpdatesController
        return


    EditFundInvestorTestimonialsController = (FundProfile, FundTestimonial, FCFundTestimonialData, FCFundData) ->
        vm = @
        vm.fund_profile = new FundProfile(FCFundData.fund_profile)
        vm.testimonials = (new FundTestimonial(t) for t in FCFundTestimonialData.investor_testimonials)

        vm.removeChild = (child_id) ->
            child_index = _.findIndex(vm.testimonials, (child) -> child.id == child_id)
            if child_index > -1
                vm.expanded_customer_child_id = null
                vm.testimonials.splice(child_index, 1)

        vm.drag_control_listeners =
            orderChanged: (event) ->
                sorted_orders = (testimonial_item.order for testimonial_item in vm.testimonials).sort (order_1, order_2) ->
                    order_1 - order_2
                if sorted_orders.length != _.unique(sorted_orders).length
                    sorted_orders = [1..vm.testimonials.length]

                testimonials_with_order = _.zip(vm.testimonials, sorted_orders)
                for [testimonial, order] in testimonials_with_order
                    if order != testimonial.order
                        testimonial.order = order
                        testimonial.$save(['order'])

        vm.testimonialCreated = (testimonial) ->
            vm.testimonials.unshift testimonial
            vm.drag_control_listeners.orderChanged(null)

        # End EditFundInvestorTestimonialsController
        return


    EditFundCustomerTestimonialsController = (FundProfile, FundTestimonial, FCFundTestimonialData, FCFundData) ->
        vm = @
        vm.fund_profile = new FundProfile(FCFundData.fund_profile)
        vm.testimonials = (new FundTestimonial(t) for t in FCFundTestimonialData.customer_testimonials)

        vm.removeChild = (child_id) ->
            child_index = _.findIndex(vm.testimonials, (child) -> child.id == child_id)
            if child_index > -1
                vm.expanded_customer_child_id = null
                vm.testimonials.splice(child_index, 1)

        vm.drag_control_listeners =
            orderChanged: (event) ->
                sorted_orders = (testimonial_item.order for testimonial_item in vm.testimonials).sort (order_1, order_2) ->
                    order_1 - order_2
                if sorted_orders.length != _.unique(sorted_orders).length
                    sorted_orders = [1..vm.testimonials.length]

                testimonials_with_order = _.zip(vm.testimonials, sorted_orders)
                for [testimonial, order] in testimonials_with_order
                    if order != testimonial.order
                        testimonial.order = order
                        testimonial.$save(['order'])

        vm.testimonialCreated = (testimonial) ->
            vm.testimonials.unshift testimonial
            vm.drag_control_listeners.orderChanged(null)
        # End EditFundCustomerTestimonialsController
        return


    EditFundRunwayController = ($scope, Fund, FundRunway, FCFundData) ->
        vm = @
        vm.fund = new Fund(FCFundData.fund)
        vm.fund_runway = new FundRunway(FCFundData.fund.fund_runway)

        vm.save = ->
            vm.errors = {}
            vm.fund_runway.$save([
                'current_cash_balance',
                'monthly_burn_rate',
                'additional_notes'
            ])
                .catch (response) ->
                    angular.extend(vm.errors, response.data)
                .finally () ->
                    vm.fcSave.saveAttemptFinished(vm.errors)

        # End EditFundRunwayController
        return


    EditFundVideosController = ($q, FundProfile, Fund, FCFundData) ->
        vm = @
        vm.fund = new Fund(FCFundData.fund)
        vm.profile = new FundProfile(FCFundData.fund_profile)

        vm.save = ->
            vm.errors = {}
            fields_to_save = [
                'video_embed_url'
                'productvideo_embed_url'
            ]

            vm.profile.$save(fields_to_save)
                .catch (response) ->
                    angular.extend(vm.errors, response.data)
                .finally () ->
                    vm.fcSave.saveAttemptFinished(vm.errors)

        # End EditFundRunwayController
        return


    EditKeyInformationController = (FundProfile, Fund, FCFundData) ->
        vm = @
        vm.fund = new Fund(FCFundData.fund)
        vm.profile = new FundProfile(FCFundData.fund_profile)

        vm.save = ->
            vm.errors = {}
            vm.profile.$save([
                'key_information'
            ])
                .catch (response) ->
                    angular.extend(vm.errors, response.data)
                .finally () ->
                    vm.fcSave.saveAttemptFinished(vm.errors)

        # End EditKeyInformationController
        return


    EditFundTermsController = (Fund, FCFundData) ->
        vm = @
        vm.fund = new Fund(FCFundData.fund)
        vm.original_fund_max = vm.fund.fund_maximum

        _updateInvestmentTarget = ->
            # Number is not relevant for multi asset funds
            if vm.fund.multi_asset_fund
                return

            vm.fund
                .$get_investment_target({'investment_amount': vm.fund.fund_maximum, 'mgmt_fees': vm.fund.mgmt_fees})
                .then (response) ->
                    vm.fund.investment_target = response.data.investment_target

        vm.updateInvestmentTarget = _.debounce(_updateInvestmentTarget, 300)

        # mgmt fees :-(
        vm.yearly_mgmt_fees = (type, year) ->
            return (
                vm.fund.mgmt_fees[type][ (year * 4) + 0 ] +
                vm.fund.mgmt_fees[type][ (year * 4) + 1 ] +
                vm.fund.mgmt_fees[type][ (year * 4) + 2 ] +
                vm.fund.mgmt_fees[type][ (year * 4) + 3 ]
            )
        vm.total_mgmt_fees = (type) ->
            total = 0
            for year in [0 .. vm.fund.mgmt_fees_years - 1]
                total += vm.yearly_mgmt_fees(type, year)
            return total

        # save
        vm.save = ->
            orig_max = vm.original_fund_max
            new_max = vm.fund.fund_maximum
            if new_max > orig_max and vm.fund.has_waitlisted_or_additional_interest_investments
                if not confirm('
                        Waitlisted investments will automatically be accepted into the Fund,
                        up to the new maximum.
                    ')
                    return false
            else if new_max < orig_max
                if not confirm('
                        Warning: If this decrease will cause investments to become waitlisted,
                        you must manually update them. The system will not update any
                        investments automatically or send any emails. Continue?
                    ')
                    return false

            vm.errors = {}
            vm.fund.$save([
                # Keep this in sync with the FundSerializer validator
                'fund_minimum',
                'fund_maximum',
                'max_deployment',
                'fund_target',
                'min_investment_size',
                'max_investment_size',
                'multi_asset_admin_fees_withheld',
                'fees_percentage',
                'fees_cap',
                'carried_interest',
                'partnership_carried_interest',
                'fund_years',
                'mgmt_fees_years',
                'mgmt_fees',
                'number_of_spots',
            ])
                .then ->
                    vm.original_fund_max = vm.fund.fund_maximum
                .catch (response) ->
                    angular.extend(vm.errors, response.data)
                .finally () ->
                    vm.fcSave.saveAttemptFinished(vm.errors)

        # End EditFundTermsController
        return


    EditFundInvestmentHighlightsController = ($scope, Fund, FundProfile, FCFundData) ->
        vm = @
        vm.fund = new Fund(FCFundData.fund)
        vm.fund_profile = new FundProfile(FCFundData.fund_profile)
        vm.selected_investment_highlights = {}

        $scope.$watch(
            (scope) -> vm.fund_profile.investment_highlights_company_ids,
            (newValue, oldValue) ->
                vm.selected_investment_highlights = {}
                for company_id in newValue
                    vm.selected_investment_highlights[company_id] = true
        )

        vm.save = ->
            vm.fund_profile.investment_highlights_company_ids = (
                company_id for company_id of vm.selected_investment_highlights when vm.selected_investment_highlights[company_id]
            )

            vm.errors = {}
            vm.fund_profile.$save([
                'investment_highlights_company_ids',
            ])
                .catch (response) ->
                    angular.extend(vm.errors, response.data)
                .finally () ->
                    vm.fcSave.saveAttemptFinished(vm.errors)

        # End EditFundInvestmentHighlightsController
        return


    EditFundInvestmentTermsController = (InvestmentTerms, FCFundData) ->
        vm = @
        vm.terms = new InvestmentTerms(FCFundData.fund.terms)

        # End EditFundInvestmentTermsController
        return


    EditFundCurrentRoundController = (FCChoices, Fund, InvestmentTerms, CorporateAction, FCFundData, FCStartupInvestorData, FCCorporateActionsData) ->
        vm = @
        vm.fund = new Fund(FCFundData.fund)
        vm.terms = new InvestmentTerms(vm.fund.terms)
        vm.current_round = new CorporateAction(FCFundData.fund_current_round)
        vm.choices = FCChoices
        vm.company_corporate_actions = (new CorporateAction(action) for action in FCCorporateActionsData.actions)
        vm.all_investors = FCCorporateActionsData.all_investors

        vm.onStartupInvestorCreated = (startupInvestor) ->
            vm.all_investors.push(startupInvestor)
            vm.current_round.round_investors.push(startupInvestor)
            vm.form.$setDirty()

        vm.onStartupInvestorRemoved = (startupInvestor) ->
            index = _.findIndex(vm.current_round.round_investors, (investor) ->
                investor.id == startupInvestor.id
            )
            vm.current_round.round_investors.splice(index, 1)
            vm.form.$setDirty()

        vm.onStartupInvestorSelected = (startupInvestor) ->
            existingInvestor = vm.current_round.round_investors.filter((investor) ->
                investor.id == startupInvestor.id
            )
            if not existingInvestor.length
                vm.current_round.round_investors.push(startupInvestor)

        vm.onStartupInvestorUpdated = (startupInvestor, updateAllInvestors) ->
            index = _.findIndex(vm.current_round.round_investors, (investor) ->
                investor.id == startupInvestor.id
            )
            startupInvestor.is_lead = vm.current_round.round_investors[index].is_lead
            vm.current_round.round_investors[index] = startupInvestor

            if updateAllInvestors
                index = _.findIndex(vm.all_investors, (investor) ->
                    investor.id == startupInvestor.id
                )
                vm.all_investors[index] = startupInvestor

        vm.select_round = ->
            vm.terms.fundraising_round = vm.selected_round.url
            vm.errors = {}
            vm.terms.$save([
                'fundraising_round'
            ])
                .then ->
                    vm.current_round = vm.selected_round
                    FCFundData.fund_current_round = vm.current_round
                .catch (response) ->
                    angular.extend(vm.errors, response.data)
                    vm.terms.fundraising_round = null
                .finally () ->
                    vm.fcSave.saveAttemptFinished(vm.errors)

        vm.clearCurrentRound = ->
            vm.selected_round = {
                url: null
            }
            vm.select_round()

        vm.new_round = ->
            round = new CorporateAction
                company: FCCorporateActionsData.company_url
            round.$create()
                .then (response) ->
                    vm.selected_round = new CorporateAction(response)
                    vm.select_round()
                .catch (response) ->
                    alert 'Failed creating new round!'

        vm.save = ->
            vm.errors = {}
            vm.current_round.lead_investors = vm.current_round.round_investors
                .filter((investor) -> investor.is_lead)
                .map((investor) -> investor.id)
            vm.current_round.$save([
                'currency',
                'label',
                'event_date',
                'round_size',
                'amount_committed',
                'amount_closed',
                'description',
                'round_investors',
                'lead_investors',
            ])
                .catch (response) ->
                    angular.extend(vm.errors, response.data)
                .finally () ->
                    vm.fcSave.saveAttemptFinished(vm.errors)

        # End EditFundCurrentRoundController
        return


    EditFundQuestionsController = ($scope, Fund, FundQuestion, FCFundData) ->
        vm = @
        vm.questions = (new FundQuestion(question) for question in FCFundData.fund_questions)
        vm.fund = new Fund(FCFundData.fund)

        fund_question_template =
            fund_profile: FCFundData.fund_profile.url
            question: 'New Section'

        vm.drag_control_listeners =
            orderChanged: (event) ->
                sorted_orders = (question.order for question in vm.questions).sort (order_1, order_2) ->
                    order_1 - order_2
                if sorted_orders.length != _.unique(sorted_orders).length
                    sorted_orders = [1..vm.questions.length]
                questions_with_order = _.zip(vm.questions, sorted_orders)
                for [question, order] in questions_with_order
                    if order != question.order
                        question.order = order
                        question.$save(['order'])

        vm.add_question = ->
            question = new FundQuestion(fund_question_template)
            question.order = (_.max vm.questions, (question) -> question.order).order + 1
            if not question.order
                question.order = 1
            question
                .$save()
                .then ->
                    vm.questions.push question

        vm.delete_question = (question_id) ->
            question_index = _.findIndex(vm.questions, (question) -> question.id == question_id)
            question = vm.questions[question_index]

            remove = confirm("Are you sure you want to delete \"#{question.question}\"?")
            if not remove
                return

            question
                .$delete()
                .then ->
                    vm.questions.splice(question_index, 1)

        # End EditFundQuestionsController
        return


    EditFundPitchDeckController = (Fund, FundProfile, FCFundData) ->
        vm = @
        vm.fund = new Fund(FCFundData.fund)
        vm.fund_profile = new FundProfile(FCFundData.fund_profile)

        vm.get_pitch_deck_filename = ->
            if typeof vm.fund_profile.pitch_deck_file == 'string'
                return _.last(vm.fund_profile.pitch_deck_file.split('/'))
            if typeof vm.fund_profile.pitch_deck_file == 'object'
                return vm.fund_profile.pitch_deck_file.name
            else
                return 'None'

        vm.save = ->
            vm.errors = {}
            vm.fund_profile.$save([
                'pitch_deck_file',
            ])
                .then ->
                    vm.form.$setPristine()
                .catch (response) ->
                    angular.extend(vm.errors, response.data)
                .finally () ->
                    vm.fcSave.saveAttemptFinished(vm.errors)

        vm.remove_pitch_deck = ->
            vm.form.$setDirty()
            vm.fund_profile.pitch_deck_file = ''
            vm.save()

        vm.upload_pitch_deck = (file) ->
            if not file or not file.length then return
            vm.form.$setDirty()
            vm.save()

        # End EditFundPitchDeckController
        return


    EditFundCodeOfConductController = ($scope, Fund, FundProfile, FundCodeOfConduct, FCFundData) ->
        vm = @
        vm.fund = new FundProfile(FCFundData.fund)
        vm.fund_profile = new FundProfile(FCFundData.fund_profile)
        vm.coc = new FundCodeOfConduct(FCFundData.fund_code_of_conduct)

        # Our typical canned response.
        other_inv_fcvc_default = '
            We do not personally invest in companies that are funded
            by this fund outside of FundersClub. We may invest in them
            with other funds managed by FundersClub.
        '

        # Sucky hack for other_investments_in_companies_response defaults
        if vm.coc.other_investments_in_companies and not vm.coc.other_investments_in_companies_response
            vm.coc.other_investments_in_companies_response =
                if vm.fund.is_internal_fund then other_inv_fcvc_default else ''

        # Adjusts the values within the "Additional details" box.
        vm.set_default_additional_text = ->
            if vm.coc.other_investments_in_companies and vm.fund.is_internal_fund
                vm.coc.other_investments_in_companies_response = other_inv_fcvc_default
            else
                vm.coc.other_investments_in_companies_response = ''

        # Save
        vm.save = ->
            vm.errors = {}
            fields = [
                'receiving_economic_benefit',
                'investing_personally',
                'potential_conflicts',
                'other_investments_in_companies',
                'other_investments_in_companies_response',
                'invest_at_same_terms',
                'ratio',
                'receiving_advisory_shares',
                'use_other_capital',
                'own_money',
            ]

            # Check for fields that have an empty custom answer
            for f in fields
                if vm.coc[f] == null
                    vm.errors[f] = ['An alternate response is required if you would like to override the default.']

            if Object.keys(vm.errors).length > 0
                return false

            # Save
            vm.coc.$save(fields)
                .catch (response) ->
                    angular.extend(vm.errors, response.data)
                .finally () ->
                    vm.fcSave.saveAttemptFinished(vm.errors)

        # End EditFundCodeOfConductController
        return


    EditFundScreenshotsController = (FundScreenshot, FCFundData) ->
        vm = @

        vm.screenshots = (new FundScreenshot(screenshot) for screenshot in FCFundData.fund_screenshots)
        vm.expanded_child_id = null

        vm.drag_control_listeners =
            orderChanged: (event) ->
                sorted_orders = (screenshot.order for screenshot in vm.screenshots).sort (order_1, order_2) ->
                    order_1 - order_2
                if sorted_orders.length != _.unique(sorted_orders).length
                    sorted_orders = [1..vm.screenshots.length]

                screenshots_with_order = _.zip(vm.screenshots, sorted_orders)
                for [screenshot, order] in screenshots_with_order
                    if order != screenshot.order
                        screenshot.order = order
                        screenshot.$save(['order'])

        # Add a new item to the list.
        vm.itemCreated = (item) ->
            vm.screenshots.push(item)

        # Remove specified child from list.
        vm.removeChild = (child_id) ->
            vm.expanded_child_id = null
            child_index = _.findIndex(vm.screenshots, (child) -> child.id == child_id)
            vm.screenshots.splice(child_index, 1)

        # End EditFundScreenshotsController
        return

    angular
        .module 'fundersclub.funds'
        .controller 'EditFundSingleAssetDetailsController', EditFundSingleAssetDetailsController
        .controller 'EditFundMultiAssetDetailsController', EditFundMultiAssetDetailsController
        .controller 'EditFundUpdatesController', EditFundUpdatesController
        .controller 'EditFundRunwayController', EditFundRunwayController
        .controller 'EditKeyInformationController', EditKeyInformationController
        .controller 'EditFundTermsController', EditFundTermsController
        .controller 'EditFundInvestmentHighlightsController', EditFundInvestmentHighlightsController
        .controller 'EditFundInvestmentTermsController', EditFundInvestmentTermsController
        .controller 'EditFundCurrentRoundController', EditFundCurrentRoundController
        .controller 'EditFundQuestionsController', EditFundQuestionsController
        .controller 'EditFundPitchDeckController', EditFundPitchDeckController
        .controller 'EditFundCodeOfConductController', EditFundCodeOfConductController
        .controller 'EditFundVideosController', EditFundVideosController
        .controller 'EditFundScreenshotsController', EditFundScreenshotsController
        .controller 'EditFundInvestorTestimonialsController', EditFundInvestorTestimonialsController
        .controller 'EditFundCustomerTestimonialsController', EditFundCustomerTestimonialsController
